import { combineReducers } from "redux";
// import alert from "./alert";
import auth from "./auth";
// import loans from "./loans";

export default combineReducers({
  //   alert,
  auth,
  //   loans,
});
