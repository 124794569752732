export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";

export const PENDING = "PENDING";
export const COMPLETED = "COMPLETED";
export const CANCELLED = "CANCELLED";
// export const API_URL = "http://192.168.212.63:8000";
// export const API_URL = "http://localhost:8000";
export const API_URL = "https://egoras-v3-staging.egoras.com";
    
